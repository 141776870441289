import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// icons
import latest from '../assets/images/sidebar/latest.png';
import featured from '../assets/images/sidebar/featured.png';
import trending from '../assets/images/sidebar/trending.png';

import global from '../assets/images/sidebar/global.png';
import aisa from '../assets/images/sidebar/asia.png';
import africa from '../assets/images/sidebar/africa.png';
import middleEast from '../assets/images/sidebar/middleEast.png';
import europe from '../assets/images/sidebar/europe.png';
import indianOceanRegion from '../assets/images/sidebar/indianOceanRegion.png';
import northAmerica from '../assets/images/sidebar/northAmerica.png';
import southAmerica from '../assets/images/sidebar/southAmerica.png';
import oceania from '../assets/images/sidebar/oceania.png';
import technology from '../assets/images/sidebar/technology.png';
import business from '../assets/images/sidebar/business.png';
import lifestyle from '../assets/images/sidebar/lifestyle.png';
import entertainment from '../assets/images/sidebar/entertainment.png';
import sports from '../assets/images/sidebar/sports.png';

// end
function Sidebar({ closeSidebar }) {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);



    useEffect(() => {
        setSelectedLanguage(i18n.language);
    }, [i18n.language]);


    useEffect(() => {

    }, [selectedLanguage]);

    return (
        <div className='sidebar'>
            <div className="menu-bar">
                <div className="menu" style={{ textAlign: 'left', padding: 'calc(1vh + 1vw)' }}>
                    <div className='row'>
                        <div className='col-md-12 close'>
                            <p style={{marginBottom:'0px !important'}} onClick={closeSidebar} >X {t('sidebar.close')} </p>
                        </div>
                        <div className='col-md-12 header'>
                            {t('sidebar.viewNewsBy')}    
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4' style={{ paddingRight: 'calc(0.2vh + 0.2vw' }}>
                                    <a className={`nav-link ${location.pathname === '/read/latest' ? 'active' : ''}`} onClick={closeSidebar} href="/read/latest">
                                        <div className='cards'>
                                            <img alt='' src={latest}></img>&nbsp;&nbsp;{t('header.latest_article')}
                                        </div>
                                    </a>

                                </div>

                                <div className='col-md-4' style={{ paddingRight: 'calc(0.2vh + 0.2vw' }}>
                                    <a className={`nav-link ${location.pathname === '/read/featured' ? 'active' : ''}`} onClick={closeSidebar} href="/read/featured">
                                        <div className='cards'>
                                            <img alt='' src={featured}></img>&nbsp;&nbsp;{t('header.featured')}
                                        </div>
                                    </a>

                                </div>
                                <div className='col-md-4' style={{ paddingRight: 'calc(0.2vh + 0.2vw' }}>
                                    <a className={`nav-link ${location.pathname === '/read/trending' ? 'active' : ''}`} onClick={closeSidebar} href="/read/trending">
                                        <div className='cards'>
                                            <img alt='' src={trending}></img>&nbsp;&nbsp;{t('header.trending')}
                                        </div>
                                    </a>

                                </div>

                            </div>
                        </div>
                        <div className='col-md-12 header'>
                            {t('sidebar.newsByRegion')}   
                        </div>
                        <div className='col-md-12 news-card'>
                            <div className='sub'>
                                <a title={t('sidebar.category.globle')} className={`nav-link ${location.pathname === `/read/globle-55` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/globle-55`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={global}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                        {t('sidebar.category.globle')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="Asia" className={`nav-link ${location.pathname === `/read/asia-56` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/asia-56`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={aisa}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                        {t('sidebar.category.asia')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="Africa" className={`nav-link ${location.pathname === `/read/africa-57` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/africa-57`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={africa}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                        {t('sidebar.category.africa')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="Middle East" className={`nav-link ${location.pathname === `/read/middle-east-58` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/middle-east-58`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={middleEast}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                        {t('sidebar.category.middleEast')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="Europe" className={`nav-link ${location.pathname === `/read/europe-59` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/europe-59`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={europe}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                        {t('sidebar.category.europe')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-12 news-card' style={{marginBottom:0}}>
                            <div className='sub'>
                                <a title="Indian Ocean Region" className={`nav-link ${location.pathname === `/read/indian-ocean-region-68` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/indian-ocean-region-68`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={indianOceanRegion}></img>
                                        </div>
                                        <div className='col-md-12 sub-title' style={{ minHeight: 'calc(1.5vh + 1.5vw)' }}>
                                            {t('sidebar.category.indianOceanRegion')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="North America" className={`nav-link ${location.pathname === `/read/north-america-61` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/north-america-61`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={northAmerica}></img>
                                        </div>
                                        <div className='col-md-12 sub-title' style={{ minHeight: 'calc(1.5vh + 1.5vw)' , padding: '0px 20px'}}>
                                            {t('sidebar.category.northAmerica')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="South America" className={`nav-link ${location.pathname === `/read/south-america-62` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/south-america-62`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={southAmerica}></img>
                                        </div>
                                        <div className='col-md-12 sub-title' style={{ minHeight: 'calc(1.5vh + 1.5vw)' , padding: '0px 20px'}}>
                                            {t('sidebar.category.southAmerica')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="Oceania" className={`nav-link ${location.pathname === `/read/oceania-63` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/oceania-63`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={oceania}></img>
                                        </div>
                                        <div className='col-md-12 sub-title' style={{ minHeight: 'calc(1.5vh + 1.5vw)' }}>
                                            
                                            {t('sidebar.category.oceania')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>


                            </div>
                        </div>

                        <div className='col-md-12 header'>
                            {t('sidebar.newByInterest')}  
                        </div>
                        <div className='col-md-12 news-card' style={{marginBottom:0}}>
                            <div className='sub'>
                                <a title="Technology" className={`nav-link ${location.pathname === `/read/technology-65` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/technology-65`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={technology}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                              {t('sidebar.category.technology')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="Business" className={`nav-link ${location.pathname === `/read/business-66` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/business-66`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={business}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                              {t('sidebar.category.business')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="Lifestyle" className={`nav-link ${location.pathname === `/read/lifestyle-64` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/lifestyle-64`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={lifestyle}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                            {t('sidebar.category.lifestyle')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="Entertainment" className={`nav-link ${location.pathname === `/read/entertainment-67` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/entertainment-67`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={entertainment}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                            {t('sidebar.category.entertainment')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='sub'>
                                <a title="Sports" className={`nav-link ${location.pathname === `/read/sport-69` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/sport-69`} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img alt='' src={sports}></img>
                                        </div>
                                        <div className='col-md-12 sub-title'>
                                            {t('sidebar.category.sport')} 
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-12 header'>
                            {t('sidebar.otherEntites')}  
                        </div>
                        <div className='col-md-12 news-card' >
                            <div className='sub' style={{ flex: '40%' }}>
                                <div className='row'>
                                    <div className='col-md-12' style={{paddingRight: 'calc(0.2vh + 0.2vw)'}}>
                                        <button >{t('sidebar.category.highlightOfTheDays')} </button>
                                    </div>

                                </div>
                            </div>
                            <div className='sub' style={{ flex: '40%' }}>
                                <div className='col-md-12' style={{paddingLeft:'calc(0.2vh + 0.2vw)'}}>
                                    <button >{t('sidebar.category.highlightOfTheWeeks')} </button>
                                </div>
                            </div>
                            <div className='sub' style={{flex:"20%"}}>
                                <div className='col-md-12' style={{paddingRight: 'calc(0.2vh + 0.2vw)'}}>
                                    <button>{t('sidebar.category.poll')} </button>
                                </div>
                            </div>


                        </div>

                    </div>
                    {/* <h3 onClick={closeSidebar} style={{ cursor: 'pointer' }}>x</h3> */}

                    {/* <ul className="menu-links" style={{
                        padding: 0,
                        paddingBottom: 10,
                        marginBottom: 5,
                        borderBottom: '1px solid #808290',
                        textAlign: 'left'
                    }}>
                        <li >
                            <a className={`nav-link ${location.pathname === '/read/latest' ? 'active' : ''}`}  onClick={closeSidebar}  href="/read/latest">
                                <img alt='' src={Latest} width={40} height={40} alt="Latest" style={{borderRadius:'50%',border:'1px solid #fff'}} /> &nbsp;
                                <span className="text nav-text">{t('header.latest_article')}</span>
                            </a>
                        </li>
                        <li >
                            <a className={`nav-link ${location.pathname === '/read/featured' ? 'active' : ''}`}  onClick={closeSidebar}  href="/read/featured">
                                <img alt='' src={Latest} width={40} height={40} alt="Latest" style={{borderRadius:'50%',border:'1px solid #fff'}} /> &nbsp;
                                <span className="text nav-text">{t('header.featured')}</span>
                            </a>
                        </li>
                        <li>
                            <a  className={`nav-link ${location.pathname === '/read/trending' ? 'active' : ''}`}  onClick={closeSidebar}  href="/read/trending">
                                <img alt='' src={Trending} width={40} height={40} alt="Trending" style={{borderRadius:'50%',border:'1px solid #fff'}} /> &nbsp;
                                <span className="text nav-text">{t('header.trending')}</span>
                            </a>
                        </li>
                    </ul>

                    <ul className="menu-links" style={{ padding: 0, textAlign: 'left',overflowY:"scroll",height:"70vh" }}>
                        <span style={{
                            color: '#808290',
                            fontSize: 14,
                            fontWeight: 300,
                            marginBottom: 5,
                        }}>Category</span>
                        {navData.map(item => (
                            <li key={item.id} >
                                <a title={item.name} className={`nav-link ${location.pathname === `/read/${item.name}-${item.id}` ? 'active' : ''}`} onClick={closeSidebar} href={`/read/${item.name}-${item.id}`} style={{textOverflow:'ellipsis',overflow:'hidden',width:200,whiteSpace:'nowrap'}}>
                                    <img alt='' src={item.image} width={40} height={40} alt={item.name} style={{borderRadius:'50%',border:'1px solid #fff'}} /> &nbsp;
                                    <span className="text nav-text">{item.name}</span>
                                </a>
                            </li>
                        ))}
                    </ul> */}
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
