import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation, redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Container, Form, Nav, Navbar, Badge } from 'react-bootstrap';
import '../App.css';
import Logo from '../assets/images/branding/brand.png';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function MyNavbar({ handleChange, toggleSidebar, toggleSearch, isSearchVisible, buttonRef }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation(); // Move useTranslation inside the component
  const { id } = useParams();
  const [selectedSidebar, setSelectedSidebar] = useState('');
  const [navData, setNavData] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(isSearchVisible); // State to store toggleSearch value
  const target = useRef(null);
  const [showLatest, setShowLatest] = useState(false);

  const fetchData = async () => {
    let url = `http://64.227.146.52:90/wp-json/api/category_list?lang=en&page_no=1`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok for the primary API');
      }
      const result = await response.json();
      setNavData(result.results);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const close = () => {
    navigate(`/read/latest`);
    setShowLatest(true);
  };

  const redirectLatest = () =>{
    navigate(`/read/latest`);
  }
  
  useEffect(() => {
    if (id) {
      if (id.split('-').length <= 4) {
        let text = id;
        text = text.replace(/-\d+$/, "");
        let words = text.split("-");
        for (let i = 1; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        let camelCaseText = words.join("");
        console.log(camelCaseText)
        setSelectedSidebar(camelCaseText);
      } else {
        setSelectedSidebar('');
      }
    }
  }, [id]);

  useEffect(() => {
    if ((location.pathname).split('/')[1] === 'search') {
      setSelectedSidebar('');
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setIsSearchActive(isSearchVisible);
  }, [isSearchVisible]);

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary headers">
        <Container fluid>
          <Navbar.Brand>
            <button className='toggle-btn' onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </button>&nbsp;&nbsp;
            {selectedSidebar === '' ? '' :selectedSidebar == 'search'  ? '' : showLatest ? '' : (
              <Badge style={{ background: '#00D172 !important' }}>
                <button onClick={close} style={{
                  borderRadius: '50%',
                  padding: 'calc(0.1vw + 0.1vh) calc(0.2vw + 0.2vh)',
                  border: 'none',
                  background: '#fff',
                  fontSize: 'calc(0.7vw + 0.7vh)'
                }}>X</button> &nbsp;
                <span style={{ fontSize: 'calc(0.6vw + 0.6vh)' }}>
                  {t(`sidebar.category.${selectedSidebar}`)}
                </span>
              </Badge>
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0 center images" navbarScroll >
              <img src={Logo} alt="Logo" style={{cursor:'pointer'}} onClick={redirectLatest} />
            </Nav>
            <Form className="d-flex" style={{ alignItems: 'center' }}>
              <div style={{ marginRight: 10 }}>
                <button ref={buttonRef}
                  type="button"
                  className={`${isSearchActive ? 'activeColor' : 'inActiveColor'}`}
                  style={{
                    border: 'none',
                    fontSize: 'calc(0.7vw + 0.7vh)',
                    background: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={toggleSearch} // Use the toggle function passed as a prop
                >
                  <FontAwesomeIcon icon={faSearch} /> &nbsp;
                  <span style={{ fontSize: 'calc(0.6vw + 0.7vh)' }}>Search &nbsp;&nbsp;&nbsp;</span>
                </button>
              </div>
              <div>
                <Select
                  value={i18n.language}
                  label="Language"
                  onChange={handleChange}
                  style={{ fontSize: 'calc(0.9vw + 0.9vh) !important' }}
                >
                  <MenuItem value={'en'}>English</MenuItem>
                  <MenuItem value={'hn'}>Hindi</MenuItem>
                  <MenuItem value={'fr'}>French</MenuItem>
                  {/* <MenuItem value={'bl'}>Bhojpuri</MenuItem> */}
                </Select>
              </div>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default MyNavbar;
